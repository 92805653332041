@use "screensizes" as *;
//sm, md, lg, and xl mixins provided by https://medium.com/codeartisan/breakpoints-and-media-queries-in-scss-46e8f551e2f2
// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg}) {
      @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl}) {
      @content;
  }
}

@mixin material-button {
  font-family: "Roboto", sans-serif;

  &:hover {
    transition-duration: 0.3s !important;
    transform: scale(1.1);
  }
}
