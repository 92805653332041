@use "imports" as *;

html{
  scroll-behavior: smooth;
  max-width: 100vw !important;
}

body {
  max-width: 100vw !important;
  overflow-x: hidden;
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.order_card{
  background-color: $var-bgPrimary !important;
  max-width: $var-maxCardWidth;
  color: $var-darkTextPrimary !important;
}

.icon_clicker{
  cursor: pointer;
}

ul{
  list-style-type: none;
}

.modal_box {
  position: relative;
  top: 5vh;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  background-color: white;
  box-shadow:
      2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
      12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
      22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
      100px 100px 80px rgba(0, 0, 0, 0.07);
}

.create_order_btn{
  position: fixed !important;
  top: 85vh;
  right: 5vw;
}

@mixin material-button-full{
  @include mix-material-button;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, .5);
}

.contained_button{
  @include mix-material-button;
}

.outlined_button{
  @include mix-material-button;
  color: $var-primary;
  border-color: $var-primary;

  &:hover{
      background-color: $var-primary !important;
      color: rgba(0, 0, 0, 0.87);
  }
}

.text_button{
  @include mix-material-button;
}