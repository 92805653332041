$primary: #C2185B;
$secondary: #EC407A;
$bgPrimary: #121212;

$darkTextPrimary: #fff;
$darkTextSecondary: rgba(255, 255, 255, 0.7);
$darkTextDisabled: rgba(255, 255, 255, 0.5);
$textPrimary: rgba(0,0,0,.87);
$textSecondary: rgba(0,0,0,.6);
$textDisabled: rgba(0,0,0,.38);

$maxCardWidth: 200px;